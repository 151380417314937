import { z } from 'zod';

export const StaffId = z.string().brand('StaffId');
export const Staff = z.object({
  id: StaffId,
  name: z.string(),
  avatarURL: z.string().url().optional(),
  role: z.enum(['owner', 'admin', 'staff']),
  lastLoginAt: z.string().datetime({ offset: true }),
  createdAt: z.string().datetime({ offset: true }),
  updatedAt: z.string().datetime({ offset: true }),
});

export type StaffType = z.infer<typeof Staff>;
